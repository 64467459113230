import templang from "@/scripts/TempLanguage"

class Voice {
    public token: string | null = null
    public expireTime: number | null = null
    public audio: HTMLAudioElement | null = null

    public getLanguage() {
        if (templang.value.save == '保存') {
            return 'chinese'
        }
        if (templang.value.save == 'Save') {
            return 'english'
        }
    }

    public play(text: string, status: boolean) {
        if (!status) {
            return
        }
        this.audio?.pause()
        this.audio = new Audio()
        this.audio.src = `${process.env.VUE_APP_API}/voice/findVoice?text=${encodeURI(text)}&lang=${this.getLanguage()}`
        this.audio.play()
    }

    public arrayPlay(textArray: string[], status: boolean) {
        if (!status) {
            return
        }
        let index = 0;
        this.audio?.pause()
        const audio = new Audio()
        audio.src = `${process.env.VUE_APP_API}/voice/findVoice?text=${encodeURI(textArray[index])}&lang=${this.getLanguage()}`
        audio.play()
        audio.onended = () => {
            if (index < textArray.length-1) {
                index++
                audio.src = `${process.env.VUE_APP_API}/voice/findVoice?text=${encodeURI(textArray[index])}&lang=${this.getLanguage()}`
                audio.play();
            }
        }
    }
}

const voice = new Voice()
export default voice