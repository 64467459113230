export default class GlobalSesion {
    public caseHistory = {
        id: "",
        fullName: "",
        age: "",
        sex: ""
    }

    public user = {
        id: -1,
        email: '',
        fullName: "未知用户",
        admin: false,
    }
    public patient = {
        phoneNumber: '',
    }

    public currentCaseHistoryLog: number | null = null

    public PdStatus = false
}