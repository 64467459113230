export default class GlobalConfig {
    public checkPath = 1;//路径
    public checkEnable = {
        hengya: true,
        qeushiya: true,
        yaguan: true,
        zhongzhiti: true,
        qiaoyichi: true,
        qiaojiya: true,
        qiaojiya1: true,
        zushengya: true,
        ruya: true,
        quchi: true,
    };//检查
    public measure = {
        checkType: CheckType.SIX,
        addOrdelete: false,
        addedEnable: true
    }//牙周袋测量
    public bleed = {
        enable: true,
        bleedFast: true,
        bleedMark: true,
        levelEnable: false,
        checkType: CheckType.TWO
    }//出血
    public pyorrhea = { enable: true, checkType: CheckType.TWO }//溢脓
    public calculus = { enable: true, checkType: CheckType.ONE, calculusMark: true, levelEnable: false }//牙结石
    public plaque = { enable: true, checkType: CheckType.TWO, hasPlaque: true, levelEnable: false }//菌斑
    public relax = {
        enable: true,
    }//松动度
    public fork = {
        enable: true,
    }//分叉
    public hornificat = {
        enable: true,
    }//角化龈
    public occlusion = {
        enable: true,
    }//咬合
    public voice = {
        enable: true,
    }//路由语音
    public option = {
        enable: true,
    }//操作提示
}

export enum CheckType {
    ONE,
    TWO,
    FOUR,
    SIX
}