enum ToothType {
    // 恒牙
    HY='hy',
    // 缺失牙
    QSY='miss',
    // 牙冠
    YG='yg',
    // 种植体
    ZZT='zz',
    // 桥义齿
    QYC='qj',
    // 桥基牙
    QJY='qy',
    // 桥基牙（种植）
    QJY_ZZ='qjzz',
    // 阻生牙
    ZSY='zs',
    // 乳牙
    RY='ru',
    // 龋齿
    QC='qc'
}

export default ToothType