import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "./assets/css/reset.css";
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import dialogDrag from '@/assets/js/dialogdrag.js'


createApp(App).use(ElementPlus, dialogDrag, zhCn).use(router).mount('#app')
