import ToothType from "./ToothType"

export default class ToothView {
    // 牙齿类型
    public toothType = ToothType.HY
    // 测量
    public measure: Array<{ title: string, addTitle: string }> = [{ title: "", addTitle: "" }, { title: "", addTitle: "" }, { title: "", addTitle: "" }, { title: "", addTitle: "" }, { title: "", addTitle: "" }, { title: "", addTitle: "" }]
    // 松动度
    public relax: Array<number> = [0, 0, 0, 0, 0, 0]
    // 出血
    public bleed: Array<number> = [0, 0, 0, 0, 0, 0]
    // 溢脓
    public pyorrhea: Array<number> = [0, 0, 0, 0, 0, 0]
    //角化龈
    public hornificat: Array<string> = ['', '', '', '', '', '']
    //咬合
    public occlus: Array<boolean> = [false, false, false, false, false, false]
    // 牙结石
    public calculus: Array<number> = [0, 0, 0, 0, 0, 0]
    // 分叉
    public fork: Array<number> = [0, 0, 0, 0, 0, 0]
    // 菌斑
    public plaque: Array<number> = [0, 0, 0, 0, 0, 0]
}