import Language from "@/scripts/Language";

class EnglishLanguage implements Language {
    newOrselect = 'New/Search';
    save = 'Save';
    RiskAssessment = 'Risk Assessment';
    GenerateReport = 'Generate Report';
    GenerateReportTip = 'Whether to save a report';
    delete = 'Delete';
    audit = 'audit';
    mission = 'Mission';
    photographic = 'Photographic';
    FullScreen = 'FullScreen';
    Setting = 'Setting';
    //头部
    year = 'Age';
    SelectRecords = 'Select Records';
    add = 'Add';
    exit = 'Exit';
    ChangePassword = 'MUP';

    //位置
    Allteeth = "All Teeth";
    molar = "molar";
    healthIndex = 'Health Index';
    plaqueIndex = 'Plaque Index';
    LipSide = 'lip';
    PalatalSide = 'palatal';
    LingualSide = 'lingual';
    //测量
    PDtip = 'The bag depth ranges from 0 to 15, with a maximum of one decimal point';
    dangerous = "dangerous";
    addOrdel = 'Retraction/hyperplasia';
    addOrdelOpen = 'Receding proliferative opening';
    addOrdelClose = 'Receding proliferative Closing';
    PD = "PD";
    hyperplasia = 'hyperplasia';
    retreat = 'Retraction';
    backspace = 'Back Space';
    empty = 'Empty';
    Confirm = 'Confirm';
    cancel = "Cancel";

    //出血
    AllBOP = 'All BOP(+)';
    AllNotBOP = 'All BOP(-)';
    BOP = 'BOP(+)';
    NotBOP = 'BOP(-)';
    AllBOP0 = 'All BOP0(+)';
    AllBOP1 = 'All BOP1(+)';
    AllBOP2 = 'All BOP2(+)';
    AllBOP3 = 'All BOP3(+)';
    AllBOP4 = 'All BOP4(+)';
    AllBOP5 = 'All BOP4(+)';
    BOP0 = 'BOP0(+)';
    BOP1 = 'BOP1(+)';
    BOP2 = 'BOP2(+)';
    BOP3 = 'BOP3(+)';
    BOP4 = 'BOP4(+)';
    BOP5 = 'BOP5(+)';
    BleedingConfirm = 'Bleeding';
    BleedingCancel = "Blending Cancel";
    BOPindex = 'Bleeding degrees ';
    AllBOPindex = 'All bleeding  degrees ';
    AllBlending = 'All bleeding';
    AllBNotlending = 'All bleeding Cancel';

    //松动度
    relaxIndex = 'degrees';
    Odegrees = '0 degrees';
    Idegrees = 'I degrees';
    IIdegrees = 'II degrees';
    IIIdegrees = 'III degrees';
    AllOdegrees = 'All are 0 degrees';
    AllIdegrees = 'All are I degrees';
    AllIIdegrees = 'All are II degrees';
    AllIIIdegrees = 'All are III degrees';

    //分叉
    forkIndex = "degrees";
    Ofork = '0 degrees';
    Ifork = 'I degrees';
    IIfork = 'II degrees';
    IIIfork = 'III degrees';
    IVfork = 'IV degrees';
    AllOfork = 'All 0 degrees';
    AllIfork = 'All I degrees';
    AllIIfork = 'All II degrees';
    AllIIIfork = 'All III degrees';
    AllIVfork = 'All IV degrees';

    //牙列检查
    canSet = "Cannot be set to";
    AllPermanent = 'All permanent teeth';
    Permanent = 'Permanent';
    Missing = 'Missing';
    Crown = 'Crown';
    Implant = 'Implant';
    BridgeDenture = 'Bridge denture';
    Abutment = 'Abutment';
    AbutmentImplant = 'Abutment (implant)';
    Impacted = 'Impacted';
    Deciduous = 'Deciduous';
    DentalCaries = 'Dental caries';

    //溢脓
    AllPositive = 'All positive (with pyorrhea)';
    AllNegative = 'All negative (without pyorrhea)';
    Positive = 'Positive (with pyorrhea)';
    Negative = 'Negative (without pyorrhea)';

    //角化龈
    hornificat = 'Keratinized';

    //咬合
    OcclusalTraumaExist = 'occlusal trauma exist';
    NoOcclusalTrauma = 'No occlusal trauma';
    AllOcclusalTrauma = 'All are occlusal trauma';
    AllNotOcclusalTraumaExist = 'All without occlusal trauma';

    //牙结石
    All = "All are";
    calculusk = "calculus";
    calculuskCancel = 'calculus cancel';
    calculuskIndex = ' degrees';
    AllNotcalculusk = "All not calculus";
    Allcalculusk = "All calculus";
    Ocalculusk = '0 degrees';
    Icalculusk = 'I degrees';
    IIcalculusk = 'II degrees';
    IIIcalculusk = 'III degrees';
    AllOcalculusk = 'All 0 degrees';
    AllIcalculusk = 'All I degrees';
    AllIIcalculusk = 'All II degrees';
    AllIIIcalculusk = 'All III degrees';

    //菌斑
    AllNotplaque = "All not plaque";
    Allplaque = "All plaque";
    plaqueConfirm = 'plaque';
    plaqueCancel = "plaque Cancel";
    plaqueValue = 'degrees';
    Oplaque = '0 degrees';
    Iplaque = 'I degrees';
    IIplaque = 'II degrees';
    IIIplaque = 'III degrees';
    IVplaque = 'IV degrees';
    IIVplaque = 'IIV degrees';
    AllOplaque = 'All 0 degrees';
    AllIplaque = 'All I degrees';
    AllIIplaque = 'All II degrees';
    AllIIIplaque = 'All III degrees';
    AllIVplaque = 'All IV degrees';
    AllIIVplaque = 'All IIV degrees';

    //新建/查找
    id = 'id';
    new = 'New';
    select = 'Search';
    namePlaceholder = 'Please enter your name';
    agePlaceholder = 'Please enter your age';
    sexPlaceholder = 'Please enter your sex';
    Cc = 'Main Suit';
    BrushTeethBleeding = 'Brush your teeth bleeding';
    SwollenGums = 'Swollen gums';
    FoodEmbedded = 'Food embedded plug';
    LooseTeeth = 'Loose teeth';
    BadBreath = 'Bad breath';
    other = 'Other';
    MedicalHistory = 'Medical history';
    smoking = 'smoking';
    diabetes = 'diabetes';
    HeartDisease = 'HeartDisease';
    lLiverDisease = 'Liver disease';
    hypertension = 'hypertension';
    asthma = 'asthma';
    other1 = 'Others (drug allergies)';
    selectPlaceholder = 'Enter your name or number to query';
    search = 'Lookup';
    submit = 'Submit';
    man = "male";
    woman = "female";

    //风险评估
    dowloadReport = 'Dowload';
    sendHandler = "Phone Push";
    emailHandler = "Email Push";
    title = 'Risk assessment system for periodontitis recurrence';
    age = 'age';
    riskInput2 = 'Number of teeth and implants';
    riskInput3 = 'Number of examination sites per tooth/implant';
    riskInput4 = 'Number of examination bleeding sites';
    riskInput5 = 'Number of sites with probing depth ≥5mm';
    riskInput6 = 'Number of missing teeth';
    riskInput7 = 'Percentage of alveolar bone loss';
    riskInput8 = 'General condition/heredity';
    riskInput9 = 'Environment';
    riskRadio1 = 'Yes';
    riskRadio2 = 'No';
    riskRadio3 = 'Non-smoker';
    riskRadio4 = 'Quit smoking for more than 5 years';
    riskRadio5 = 'No more than 10 cigarettes per day';
    riskRadio6 = 'No more than 20 cigarettes per day';
    riskRadio7 = 'More than 20 cigarettes per day';
    advice = 'The recommended interval for follow-up is';
    highrisk = 'High risk';
    mediarisk = 'Middle risk';
    lowrisk = 'Low risk';
    monthyear = 'month';
    chart1 = 'Daily smoking';
    chart2 = 'System disease or Susceptible gene';
    chart3 = 'Bone loss/Age(%)';
    chart4 = 'Number of missing teeth';
    reference = 'Reference for PRA: Lang NP, Tonetti MS. Periodontal risk assessment (PRA) for patients in supportive periodontal therapy (SPT). Oral Health Prev Dent. 2003;1(1):7-16. PMID: 15643744.(periodontal risk assessment;PRA)';

    //删除记录
    deleteTip = 'Are you sure to delete the record?';
    deletePhotoTip = 'Are you sure to delete the photo?';

    //拍照
    PhotoUpload = 'Photo upload';
    UploadSuccessTip = 'Photo uploading successfully; Click on the album to view';
    UploadErrorTip = 'Upload up to 12 images';
    notDevices = 'No camera device detected';
    photoSizeTip = "The image size cannot be larger than 1M";

    //设置
    checkType = "Please set the dentition examination category";
    checkPath = 'Examination route';
    teethCheck = 'Dentition Examination';
    PeriodontalPocketDepthMeasurement = 'Periodontal pocket depth';
    Bleeding = 'Bleeding';
    Pyorrhea = 'Pyorrhea';
    Calculus = 'Dental calculus';
    plaque = 'Bacterial plaque';
    relax = 'Mobility';
    fork = 'Root furcation';
    Keratinized = 'Keratinized gingiva';
    occlus = 'Occlusal trauma';
    voice = "Voice configuration";
    chinese = "Chinese";
    english = "English";
    open = "open";
    close = "close";
    RoutingSwitch = "Routing Switch";
    optionTip = 'Option Tip';
    connected = "connect success";
    toConnect = 'Click on the link';

    //检查路径
    path1 = 'Route 1';
    path2 = 'Route 2';
    path3 = 'Route 3';
    path4 = 'Route 4';
    path5 = 'Route 5';
    path6 = 'Route 6';


    //点法
    oneTip = '1-points method';
    fourTip = '4-points method';
    twoTip = '2-points method';
    sixTip = '6-points method';

    //牙周袋深度测量
    PeriodontalTitle = 'Periodontal pocket measurement';

    measure = 'Measure tooth retraction/hyperplasia';

    //出血
    BleedTitle = 'Bleeding';
    BleedingRecordSwitch = 'Bleeding record switch';
    BleedmarkFast = 'Whether to enable the shortcut key of bleeding/purulent mark';
    Bleedselect1 = 'Please select the method of recording bleeding site for each tooth';
    Bleedselect2 = 'Please select bleeding recording method';
    BleedSwitch1 = 'BOP marking: BOP(+)/BOP(-)';
    BleedSwitch2 = 'Marking bleeding index (only for 2-point method)';

    //溢脓
    PyorrheaTitle = 'Pyorrhea';
    PyorrheaingRecordSwitch = 'Pyorrhea recording';
    Pyorrheaselect1 = 'Please select the method of recording pyorrhea site for each tooth';

    //牙结石
    CalculusTitle = 'Dental calculus';
    CalculusingRecordSwitch = 'Dental calculus recording';
    Calculusselect1 = 'Please select the method of recording dental calculus site for each tooth';
    Calculusselect2 = 'Please select the recording way of dental calculus';
    CalculusSwitch1 = 'Positive (with dental calculus)/ negative (without dental calculus)';
    CalculusSwitch2 = 'Dental calculus index';

    //菌斑
    Notplaque = "negative (without plaque)";
    Hasplaque = "Positive (with plaque)";
    plaqueTitle = 'Bacterial plaque';
    plaqueingRecordSwitch = 'Bacterial plaque recording';
    plaqueselect1 = 'Please select the method of recording plaque site for each tooth';
    plaqueselect2 = 'Please select the recording way of dental calculus';
    plaqueSwitch1 = 'Positive (with plaque)/ negative (without plaque)';
    plaqueSwitch2 = 'Bacterial plaque index';

    //报告
    ExactPD = 'exact PD';
    VaguePD = 'Vague PD';
    briefing = 'briefing';
    DetailedReport = 'Detailed Report';
    StatisticalAnalysis = 'Statistical Analysis';
    PeriodontalExaminationReport = 'Periodontal examination report';
    name = "username";
    sex = "sex";
    DisplayTheDate = 'Examination date';
    ComparisionDate = "Comparision date";
    CurrentDate = "Current examination date";
    selectDate = "Click to select the comparison date";
    doctor = "Doctor";
    nianling = 'age';
    change = "change";
    BleedingAndPyorrhea = "Bleeding Pyorrhea";
    zerolevel = '0 degrees';
    onelevel = 'I degrees';
    twolevel = 'II degrees';
    threelevel = 'III degrees';
    fourlevel = 'Ⅳ degrees';
    health = "Health";
    PD1 = 'PD<4mm';
    PD2 = '4mm<PD<6mm';
    PD3 = 'PD>=6mm';
    tip = 'Periodontal infection risk index (PIRI)';
    level = "level";
    period = "period";
    high = 'high';
    media = "middle";
    low = "low";
    risk = "risk";
    note = "Note: Patients with PIRI score of 0 have a low risk of periodontal system complications, patients with PIRI score of 1-5 are at moderate risk, and patients with PIRI score of 6-10 are at high risk"
    report1 = 'Diagnosis';
    DegreeOfControl = "Degree of control";
    status1 = 'Periodontal clinical health';
    status2 = 'Localized gingivitis';
    status3 = 'Extensive gingivitis';
    status4 = 'Localized periodontitis';
    status5 = 'Extensive periodontitis';
    status6 = 'Cut - grinding periodontitis';
    status7 = 'No diagnosis';
    OcclusContact = "Occlusal relationship";
    stable = "stable";
    unstable = "unstable";
    ease = "ease";
    variation = "variation";
    hasPlaque = "Has Plaque";
    noPlaque = "No Plaque";
    PD4mm = "PD>=4mm";
    PDAndBleed = "PD and Bleeding";
    AttachmentLoss = "Attachment Loss";
    AttachmentLoss1 = "Attachment Loss<2mm";
    AttachmentLoss2 = "2mm<Attachment Loss<5mm";
    AttachmentLoss3 = "Attachment Loss>=5mm";
    PatientsProgress = "Patients with Progress";
    date = "date";
    reportImg = `url(${require('@/assets/img/report/11.png')})`
    pushSuccess = 'Push success'
    push = 'push'
    email = 'email'
    format = "format is incorrect"

    //咬合关系
    selectOcclus = "Please select occlusal relationship";
    TeethStatus1 = "Teeth normal";
    TeethStatus2 = "Tooth column";
    TeethStatus3 = "Tooth column crowded";
    TeethStatus4 = "Teeth sparse";
    TeethStatus5 = "Center line is not aligned";
    TeethStatus6 = "Deep cover";
    TeethStatus7 = "reverse occlusion";
    TeethStatus8 = "Deep overburden occlusion";
    TeethStatus9 = "open occlusion";
    TeethStatus10 = "partial occlusion";

    //登录
    welcome = "welcome to use Shenzhen SOGA Periodontal Examination System";
    LookupReport = "Lookup Report";
    phone = "phone";
    VerificationCode = "Verification code";
    login = "Login";
    sendCode = "Send Code";
    phoneTip1 = "The phone number format is incorrect";
    phoneTip2 = "The phone number does not exist";
    phoneTip3 = "The phone number does not registered";
    resendCode = "second after resend";
    password = "Password";
    registered = "registered";
    remeberPassword = "remember Password";
    forgetPassword = "forget Password?";
    rePassword = "Enter your password again";
    affiliation = "affiliation";
    sn = "SN";
    cdk = "CD-Key"
    backLogin = "Back to Login";
    passwordTip1 = "The two passwords are inconsistent";
    passwordTip2 = "The new passwords are not empty";
    registeredTip1 = "Registration is successful, please wait for administrator review";
    SelectDiagnosticRecord = "Select Diagnostic Record";
    confirmPhone = 'confirm Phone';
    resetPassword = "reset Password";
    resetSuccess = "reset Success";
    newPassword = "new Password";
    confirmPassword = "confirm Password";
    codeTip = "Please click in one minute";
    saveSuccess = "Save success";
    recordTip1 = "A record had exist on the current day. Overwrite?";
    recordTip2 = "No diagnostic record is selected";
    recordTip3 = "Please Select or save the diagnostic record";
    recordTip4 = "The application is recommended to be used in full-screen mode. Enable full-screen mode?";
    openFullScreen = "Open the full screen";
    currentSituation = "keep current situation";
    disconnect = "disconnect";
    ageTip = "Please enter numbers in the correct format";


    //拍照
    SwitchingCameras = "Switching Cameras";
    TakingPictures = "Taking Pictures";
    PhotoAlbum = "Photo Album";

    //语音
    model = "model";

    //诊断
    diagnosisTip1 = "Medical history, clinical examination and auxiliary examination";
    diagnosisTip2 = "gingivitis";
    diagnosisTip3 = "periodontal clinical health";
    diagnosisTip4 = "localized gingivitis";
    diagnosisTip5 = "extensive gingivitis";
    diagnosisTip6 = "Type: number and distribution of affected teeth";
    diagnosisTip7 = "of the affected teeth";
    diagnosisTip8 = "One-piece model: only incisors and molars lose attachment";
    diagnosisTip9 = "localized periodontitis";
    diagnosisTip10 = "extensive periodontitis";
    diagnosisTip11 = "all-molar periodontitis";
    diagnosisTip12 = "The Proportion of teeth affected is";
    diagnosisTip13 = "Staging: extent of disease destruction and treatment complexity";
    diagnosisTip14 = "Staging";
    diagnosisTip15 = "severity";
    diagnosisTip16 = "maximum interproximal CAL";
    diagnosisTip17 = "imaging bone resorption to";
    diagnosisTip18 = "Crown 1/3(<15%)";
    diagnosisTip19 = "Crown 1/3(33%~66%)";
    diagnosisTip20 = "1/3(33%~66%)";
    diagnosisTip21 = "Tip 1/3(>66%)";
    diagnosisTip22 = "Tooth loss due to periodontitis";
    diagnosisTip23 = "NO";
    diagnosisTip24 = "Complexity";
    diagnosisTip25 = "local condition";
    diagnosisTip26 = "Maximum PD≤4 mm;Mainly horizontal bone resorption";
    diagnosisTip27 = "Maximum PD≤5mm;Mainly horizontal bone resorption";
    diagnosisTip28 = "On the basis of stage II status: PD ≥ 6 mm; vertical bone resorption ≥ 3 mm; I or III II furcation lesions; moderate teeth in edentulous areas alveolar defect";
    diagnosisTip29 = "On the basis of stage III status: need more complex restorative treatment; masticatory dysfunction; secondary occlusal trauma (degree 2 and above loosening); severe alveolar bone defect in the edentulous area: occlusal disorder; tooth displacement: less than 20 remaining teeth (10 pairs of occlusal teeth)";
    diagnosisTip30 = "Grading: the rate or risk of disease progression: response to treatment";
    diagnosisTip31 = "Grading";
    diagnosisTip32 = "Class A ：slow progress";
    diagnosisTip33 = "Class B：medium speed progress";
    diagnosisTip34 = "Class C：rapid progress";
    diagnosisTip35 = "Primary indicator";
    diagnosisTip36 = "Direct evidence: imaging or CAL follow-up data";
    diagnosisTip37 = "No further attachment and loss of evidence in 5 years";
    diagnosisTip38 = "Loss of attachment in 5 years < 2mm";
    diagnosisTip39 = "Attachment loss ≥ 2mm during 5 years";
    diagnosisTip40 = "circumstantial evidence";
    diagnosisTip41 = "Percentage of bone loss/age";
    diagnosisTip42 = "Individual performance";
    diagnosisTip43 = "Large amount of plaque deposition with low level damage";
    diagnosisTip44 = "The degree of damage is commensurate with the amount of plaque";
    diagnosisTip45 = "The degree of destruction exceeds the amount of plaque deposition, showing rapid progression and / or early publication (cutting-grinding type: poor response to conventional treatment)";
    diagnosisTip46 = "Degree of control: current control degree of periodontitis";
    diagnosisTip47 = "Stability (periodontal clinical health)： PD<4mm, and BOP(+) <10%, PD=4mm site BOP (-)";
    diagnosisTip48 = "Relief (gingivitis)：Relieves (gingivitis) PD < 4 mm, and BOP(+) ≥ 10%, PD = 4 mm site BOP (-)";
    diagnosisTip49 = "Unstable (uncontrolled)：≥5mm,or OD≥4mm,and PD=4mm site BOP(+)";
    diagnosisTip50 = "periodontitis";

    //路由
    routerImg1 = require('@/assets/img/nav/jianbao1.png');
    routerImg2 = require('@/assets/img/nav/celiang1.png');
    routerImg3 = require('@/assets/img/nav/chuxue1.png');
    routerImg4 = require('@/assets/img/nav/yinong1.png');
    routerImg5 = require('@/assets/img/nav/songdongdu1.png');
    routerImg6 = require('@/assets/img/nav/fencha1.png');
    routerImg7 = require('@/assets/img/nav/yaliejiancha1.png');
    routerImg8 = require('@/assets/img/nav/jiaohuayin1.png');
    routerImg9 = require('@/assets/img/nav/yaohe1.png');
    routerImg10 = require('@/assets/img/nav/yajieshi1.png');
    routerImg11 = require('@/assets/img/nav/junban1.png');

    //宣教
    video1Name = 'Tooth looseness';
    video2Name = 'retreat';
    video3Name = 'Swollen and inflamed teeth';
    video4Name = 'halitosis';
    video5Name = 'Bleeding';
    video6Name = 'Aurora periodontal therapy';
    video7Name = 'Flap curettage';
    video8Name = 'Redness and swelling';

    public getSex(params: string): string {
        return params == "男" ? 'male' : 'female'
    }
    url = require('@/assets/img/supCheck1.png');
    srcList = [require('@/assets/img/supCheck1.png')];
    imgList = [
        {
            name: 'Accessory Examination',
            url: require('@/assets/img/supCheck1.png'),
        },
        {
            name: 'Judgment of periodontitis',
            url: require('@/assets/img/judge1.png'),
        }
    ]
}
const englishLanguage = new EnglishLanguage();
export default englishLanguage