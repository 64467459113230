import Language from "@/scripts/Language";

class ChineseLanguage implements Language {
    newOrselect = '新建/查找';
    save = '保存';
    RiskAssessment = '风险评估';
    GenerateReport = '生成报告';
    GenerateReportTip = '是否先保存报告';
    delete = '删除';
    audit = '审核';
    mission = '宣教';
    photographic = '照相';
    FullScreen = '全屏';
    Setting = '设置';
    //头部
    year = '岁';
    SelectRecords = '选择记录';
    add = '新增';
    exit = '退出';
    ChangePassword = '修改密码';


    //位置
    Allteeth = "所有牙齿";
    molar = "磨牙";
    healthIndex = '正常指数';
    plaqueIndex = '菌斑指数';
    LipSide = '唇侧';
    PalatalSide = '腭侧';
    LingualSide = '舌侧';

    //测量
    PDtip = '袋深范围在0-15，且小数点后最多只能一位';
    dangerous = "危险";
    addOrdel = '退缩/增生';
    addOrdelOpen = '退缩增生开启';
    addOrdelClose = '退缩增生关闭';
    PD = "袋深";
    hyperplasia = '增生';
    retreat = '退缩';
    backspace = '退格';
    empty = '清空';
    Confirm = '确定';
    cancel = "取消";

    //出血
    AllBOP = '全为出血';
    AllNotBOP = '全为未出血';
    BOP = '出血';
    NotBOP = '未出血';
    AllBOP0 = '全为出血指数0';
    AllBOP1 = '全为出血指数1';
    AllBOP2 = '全为出血指数2';
    AllBOP3 = '全为出血指数3';
    AllBOP4 = '全为出血指数4';
    AllBOP5 = '全为出血指数5';
    BOP0 = '出血指数0';
    BOP1 = '出血指数1';
    BOP2 = '出血指数2';
    BOP3 = '出血指数3';
    BOP4 = '出血指数4';
    BOP5 = '出血指数5';
    BleedingConfirm = '出血';
    BleedingCancel = "出血取消";
    BOPindex = '出血指数';
    AllBOPindex = '全为出血指数';
    AllBlending = '全为出血';
    AllBNotlending = '全为未出血';

    //松动度
    relaxIndex = '度松动';
    Odegrees = '0度松动';
    Idegrees = 'I度松动';
    IIdegrees = 'II度松动';
    IIIdegrees = 'III度松动';
    AllOdegrees = '全为0度松动';
    AllIdegrees = '全为I度松动';
    AllIIdegrees = '全为II度松动';
    AllIIIdegrees = '全为III度松动';

    //分叉
    forkIndex = "度分叉";
    Ofork = '0度分叉';
    Ifork = 'I度分叉';
    IIfork = 'II度分叉';
    IIIfork = 'III度分叉';
    IVfork = 'IV度分叉';
    AllOfork = '全为0度分叉';
    AllIfork = '全为I度分叉';
    AllIIfork = '全为II度分叉';
    AllIIIfork = '全为III度分叉';
    AllIVfork = '全为IV度分叉';

    //牙列检查
    canSet = "不能设置为";
    AllPermanent = '全为恒牙';
    Permanent = '恒牙';
    Missing = '缺失牙';
    Crown = '牙冠';
    Implant = '种植体';
    BridgeDenture = '桥义齿';
    Abutment = '桥基牙';
    AbutmentImplant = '桥基牙(种植)';
    Impacted = '阻生牙';
    Deciduous = '乳牙';
    DentalCaries = '龋齿';

    //溢脓
    AllPositive = '全为阳性(有溢脓)';
    AllNegative = '全为阴性(无溢脓)';
    Positive = '阳性(有溢脓)';
    Negative = '阴性(无溢脓)';

    //角化龈
    hornificat = '角化龈';

    //咬合
    OcclusalTraumaExist = '咬合创伤:有';
    NoOcclusalTrauma = '咬合创伤:无';
    AllOcclusalTrauma = '全为咬合创伤';
    AllNotOcclusalTraumaExist = '全为咬合创伤无';

    //牙结石
    All = "全为";
    calculusk = "牙结石";
    calculuskCancel = '牙结石取消';
    calculuskIndex = '度牙结石';
    Allcalculusk = "全为牙结石";
    AllNotcalculusk = "全部牙结石取消";
    Ocalculusk = '0度牙结石';
    Icalculusk = 'I度牙结石';
    IIcalculusk = 'II度牙结石';
    IIIcalculusk = 'III度牙结石';
    AllOcalculusk = '全为0度牙结石';
    AllIcalculusk = '全为I度牙结石';
    AllIIcalculusk = '全为II度牙结石';
    AllIIIcalculusk = '全为III度牙结石';

    //菌斑
    Notplaque = "无菌斑(阴性)";
    Hasplaque = "菌斑(阳性)";
    AllNotplaque = "全为阴性";
    Allplaque = "全为阳性";
    Oplaque = '菌斑指数0';
    Iplaque = '菌斑指数1';
    IIplaque = '菌斑指数2';
    IIIplaque = '菌斑指数3';
    IVplaque = '菌斑指数4';
    IIVplaque = '菌斑指数5';
    plaqueConfirm = '菌斑';
    plaqueCancel = "菌斑取消";
    plaqueValue = '度菌斑';
    AllOplaque = '全为菌斑指数0';
    AllIplaque = '全为菌斑指数1';
    AllIIplaque = '全为菌斑指数2';
    AllIIIplaque = '全为菌斑指数3';
    AllIVplaque = '全为菌斑指数4';
    AllIIVplaque = '全为菌斑指数5';

    //新建/查找
    id = '编号';
    new = '新建';
    select = '查找';
    namePlaceholder = '请输入姓名';
    agePlaceholder = '请输入年龄';
    sexPlaceholder = '请选择性别';
    Cc = '主诉';
    BrushTeethBleeding = '刷牙出血';
    SwollenGums = '牙龈红肿';
    FoodEmbedded = '食物嵌塞';
    LooseTeeth = '牙齿松动';
    BadBreath = '口臭';
    other = '其他';
    MedicalHistory = '既往病史';
    smoking = '吸烟';
    diabetes = '糖尿病';
    HeartDisease = '心脏疾病';
    lLiverDisease = '肝疾病';
    hypertension = '高血压';
    asthma = '哮喘';
    other1 = '其他(药物过敏性)';
    selectPlaceholder = '输入姓名或编号查询';
    search = '查询';
    submit = '提交';
    man = "男";
    woman = "女";

    //风险评估
    dowloadReport = '下载报告';
    sendHandler = "手机推送";
    emailHandler = "邮箱推送";
    title = '牙周炎复发危险评估系统';
    age = '年龄';
    riskInput2 = '牙齿及种植体数目';
    riskInput3 = '每颗牙/种植体检查位点数';
    riskInput4 = '探诊出血位点数';
    riskInput5 = '探诊深度≥5mm的位点数目';
    riskInput6 = '缺失牙齿数目';
    riskInput7 = '牙槽骨丧失百分比';
    riskInput8 = '全身状况/遗传';
    riskInput9 = '环境';
    riskRadio1 = 'Yes';
    riskRadio2 = 'No';
    riskRadio3 = '不吸烟';
    riskRadio4 = '戒烟5年以上';
    riskRadio5 = '日吸烟量≤10';
    riskRadio6 = '日吸烟量≤20';
    riskRadio7 = '日吸烟量≥20';
    advice = '建议复诊间隔';
    reference = '引用Lang和Tonetti在2003年提出的牙周风险评估系统(periodontal risk assessment;PRA)';
    highrisk = '高风险';
    mediarisk = '中风险';
    lowrisk = '低风险';
    monthyear = '个月';
    chart1 = '日吸烟量';
    chart2 = '系统疾病或易感基因';
    chart3 = '骨失量/年龄(%)';
    chart4 = '失牙数';

    //删除记录
    deleteTip = '删除?确定删除该记录吗?';
    deletePhotoTip = '删除?确定删除该图片?';


    //拍照
    PhotoUpload = '拍照上传';
    UploadSuccessTip = '拍照上传成功;可点击相册查看';
    UploadErrorTip = '至多上传十二张图片';
    notDevices = '未检测到拍照设备';
    photoSizeTip = "图片大小不能大于1M";

    //设置
    checkType = "请设置牙列检查类目";
    checkPath = '检查路径';
    teethCheck = '牙列检查';
    PeriodontalPocketDepthMeasurement = '牙周袋深度测量';
    Bleeding = '出血';
    Pyorrhea = '溢脓';
    Calculus = '牙结石';
    plaque = '菌斑';
    relax = '松动度';
    fork = '根分叉';
    Keratinized = '角化龈';
    occlus = '咬合创伤';
    voice = "语音配置";
    chinese = "中文";
    english = "英文";
    open = "开启";
    close = "关闭";
    RoutingSwitch = "路由切换";
    optionTip = '操作提示';
    connected = '已连接';
    toConnect = "点击连接";


    //检查路径
    path1 = '路径一';
    path2 = '路径二';
    path3 = '路径三';
    path4 = '路径四';
    path5 = '路径五';
    path6 = '路径六';


    //点法
    oneTip = '一点法';
    fourTip = '四点法';
    twoTip = '二点法';
    sixTip = '六点法';

    //牙周袋深度测量
    PeriodontalTitle = '牙周袋测量';

    measure = '测量牙齿退缩/增生';

    //出血
    BleedTitle = '出血';
    BleedingRecordSwitch = '是否开始出血测量';
    BleedmarkFast = '是否开启出血/溢脓标记快捷键';
    Bleedselect1 = '请选择每牙位出血位点记录法';
    Bleedselect2 = '请选择探针出血记录方式';
    BleedSwitch1 = '标记出血BOP(+)/未出血BOP(-)';
    BleedSwitch2 = '标记出血指数(仅2点法可选)';

    //溢脓
    PyorrheaTitle = '溢脓';
    PyorrheaingRecordSwitch = '是否开始溢脓测量';
    Pyorrheaselect1 = '请选择每牙位溢脓位点记录法';

    //牙结石
    CalculusTitle = '牙结石';
    CalculusingRecordSwitch = '是否开始牙结石测量';
    Calculusselect1 = '请选择每牙位牙结石位点记录法';
    Calculusselect2 = '请选择探针牙结石记录方式';
    CalculusSwitch1 = '阳性（有牙结石）/阴性（无牙结石）';
    CalculusSwitch2 = '标记牙结石指数';

    //菌斑
    plaqueTitle = '菌斑';
    plaqueingRecordSwitch = '是否开始菌斑测量';
    plaqueselect1 = '请选择每牙位菌斑位点记录法';
    plaqueselect2 = '请选择探针菌斑记录方式';
    plaqueSwitch1 = '阳性（有菌斑）/阴性（无菌斑）';
    plaqueSwitch2 = '标记菌斑指数';

    //报告
    ExactPD='精确袋深';
    VaguePD='模糊袋深';
    briefing = '简报';
    DetailedReport = '详细报告';
    StatisticalAnalysis = '统计分析';
    PeriodontalExaminationReport = '牙周检测报告';
    name = "姓名";
    sex = "性别";
    DisplayTheDate = '显示日期';
    ComparisionDate = "对比日期";
    CurrentDate = "当前诊断日期";
    selectDate = "点击选择对比日期";
    doctor = "医生";
    nianling = '年龄';
    change = "修改";
    BleedingAndPyorrhea = "出血溢脓";
    zerolevel = '0级';
    onelevel = 'I级';
    twolevel = 'II级';
    threelevel = 'III级';
    fourlevel = 'Ⅳ级';
    health = "健康";
    PD1 = '袋深<4mm';
    PD2 = '4mm<袋深<6mm';
    PD3 = '袋深>=6mm';
    tip = '牙周感染风险指数(PIRI)';
    level = "级";
    period = "期";
    high = '高';
    media = "中";
    low = "低";
    risk = "风险";
    note = "注:PIRI计分为0的患者，其牙周系统并发症的风险较低，积分为1-5的患者属于中度风险，而6-10的患者为高风险";
    report1 = '诊断（2018年新分类）';
    DegreeOfControl = "控制程度";
    status1 = '牙周临床健康';
    status2 = '局限型牙龈炎';
    status3 = '广泛型牙龈炎';
    status4 = '局限型牙周炎';
    status5 = '广泛性牙周炎';
    status6 = '切-磨型牙周炎';
    status7 = '未诊断';
    OcclusContact = "咬合关系";
    stable = "稳定";
    unstable = "不稳定";
    ease = "缓解";
    variation = "变化";
    hasPlaque = "有菌斑";
    noPlaque = "无菌斑";
    PD4mm = "袋深>=4mm";
    PDAndBleed = "袋深和出血";
    AttachmentLoss = "附着丧失";
    AttachmentLoss1 = "附着丧失<2mm";
    AttachmentLoss2 = "2mm<附着丧失<5mm";
    AttachmentLoss3 = "附着丧失>=5mm";
    PatientsProgress = "患者进展";
    date = "日期";
    reportImg = `url(${require('@/assets/img/report/10.png')})`
    pushSuccess='推送成功'
    push='推送'
    email='邮箱'
    format='格式不正确'

    //咬合关系
    selectOcclus = "请选择咬合关系";
    TeethStatus1 = "牙列正常";
    TeethStatus2 = "牙列不齐";
    TeethStatus3 = "牙列拥挤";
    TeethStatus4 = "牙列稀疏";
    TeethStatus5 = "中线不对齐";
    TeethStatus6 = "深覆盖";
    TeethStatus7 = "反𬌗";
    TeethStatus8 = "深覆𬌗";
    TeethStatus9 = "开𬌗";
    TeethStatus10 = "偏𬌗";

    //登录
    welcome = "欢迎使用索感探针系统";
    LookupReport = "查询报告";
    phone = "手机号";
    VerificationCode = "验证码";
    login = "登录";
    sendCode = "发送验证码";
    phoneTip1 = "手机号格式不正确";
    phoneTip2 = "手机号不存在";
    phoneTip3 = "该手机号未注册";
    resendCode = "s后重发";
    password = "密码";
    registered = "注册";
    remeberPassword = "记住密码";
    forgetPassword = "忘记密码?";
    rePassword = "请再次输入密码";
    affiliation = "所属机构";
    sn = "设备号";
    cdk = "激活码";
    backLogin = "返回登录";
    passwordTip1 = "两次密码输入不一致";
    passwordTip2 = "新密码不能为空";
    registeredTip1 = "注册成功,请等待管理员审核";
    SelectDiagnosticRecord = "请选择诊断记录";
    confirmPhone = '确认手机号';
    resetPassword = "重置密码";
    resetSuccess = "重置成功";
    newPassword = "新密码";
    confirmPassword = "确认密码";
    codeTip = "请在一分钟后点击";
    saveSuccess = "保存成功";
    recordTip1 = "检测到当天已有记录，是否覆盖？";
    recordTip2 = "未选择诊断记录";
    recordTip3 = "请选择或保存诊断记录！";
    recordTip4 = "应用推荐全屏下使用，是否开启全屏？";
    openFullScreen = "开启全屏";
    currentSituation = "保持现状";
    disconnect = "断开连接";
    ageTip = "请输入正确格式的数字";


    //拍照
    SwitchingCameras = "切换摄像头";
    TakingPictures = "拍照";
    PhotoAlbum = "相册";

    //语音
    model = "模式";

    //诊断
    diagnosisTip1 = "2018年新分类的牙周炎症状态临床诊断思维导图";
    diagnosisTip2 = "牙龈炎";
    diagnosisTip3 = "牙周临床健康";
    diagnosisTip4 = "局限型牙龈炎";
    diagnosisTip5 = "广泛型牙龈炎";
    diagnosisTip6 = "分型：受累牙齿数量和分布";
    diagnosisTip7 = "牙齿受累";
    diagnosisTip8 = "仅切牙、磨牙附着丧失";
    diagnosisTip9 = "局限型牙周炎";
    diagnosisTip10 = "广泛型牙周炎";
    diagnosisTip11 = "切一磨型牙周炎";
    diagnosisTip12 = "受累牙占比为 :";
    diagnosisTip13 = "分期：疾病的破坏程度及治疗的复杂性";
    diagnosisTip14 = "分期";
    diagnosisTip15 = "严重程度";
    diagnosisTip16 = "最大邻面CAL";
    diagnosisTip17 = "影像学骨吸收至";
    diagnosisTip18 = "冠1/3(<15%)";
    diagnosisTip19 = "冠1/3(15%~33%)";
    diagnosisTip20 = "延伸到牙根1/2或根尖1/3";
    diagnosisTip21 = "延伸到牙根1/2或根尖1/3";
    diagnosisTip22 = "因牙周炎失牙数";
    diagnosisTip23 = "无";
    diagnosisTip24 = "复杂程度";
    diagnosisTip25 = "局部状况";
    diagnosisTip26 = "最大PD ≤ 4mm;以水平骨吸收为主";
    diagnosisTip27 = "最大PD ≤ 5mm;以水平骨吸收为主";
    diagnosisTip28 = "在II期状态基础上;PD≧6mm;垂直型骨吸收≥3mm;II度或III度根分叉病变;缺牙区中度牙槽骨缺损";
    diagnosisTip29 = "在III期状态基础上;需要更复杂的修复治疗;咀嚼功能障碍;继发咬合创伤(2度及以上松动);缺牙区重度牙槽骨缺损;咬合紊乱;牙齿移位;余留牙少于20颗(10对咬合牙)";
    diagnosisTip30 = "分级：疾病进展的速度或风险，对治疗的反应";
    diagnosisTip31 = "分级";
    diagnosisTip32 = "A级 慢速进展";
    diagnosisTip33 = "B级 中速进展";
    diagnosisTip34 = "C级 快速进展";
    diagnosisTip35 = "首要指标";
    diagnosisTip36 = "直接证据：映像学或CAL随访资料";
    diagnosisTip37 = "5年中无进一步附着丧失的证据";
    diagnosisTip38 = "5年中附着丧失<=2mm";
    diagnosisTip39 = "5年中附着丧失>=2mm";
    diagnosisTip40 = "间接证据";
    diagnosisTip41 = "骨丧失百分比/年龄";
    diagnosisTip42 = "个体表现";
    diagnosisTip43 = "大量菌斑沉积伴低水平破坏";
    diagnosisTip44 = "破坏程度与菌斑量相称";
    diagnosisTip45 = " 破坏程度超过菌斑沉积量";
    diagnosisTip46 = "控制程度：目前牙周炎控制程度";
    diagnosisTip47 = "稳定(牙周临床健康)PD≤4mm,且BOP(+)<10%,PD=4mm的位点BOP(-)";
    diagnosisTip48 = "缓解(牙龈炎) PD＜4mm,且BOP(+)≥10PD=4mm的位点BOP (一)";
    diagnosisTip49 = "不稳定、（未控制）： PD25 mm,或者PD 24 mm, 且PD=4mm鬲位点BOP(-)";
    diagnosisTip50 = "牙周炎";

    //路由
    routerImg1 = require('@/assets/img/nav/jianbao.png');
    routerImg2 = require('@/assets/img/nav/celiang.png');
    routerImg3 = require('@/assets/img/nav/chuxue.png');
    routerImg4 = require('@/assets/img/nav/yinong.png');
    routerImg5 = require('@/assets/img/nav/songdongdu.png');
    routerImg6 = require('@/assets/img/nav/fencha.png');
    routerImg7 = require('@/assets/img/nav/yaliejiancha.png');
    routerImg8 = require('@/assets/img/nav/jiaohuayin.png');
    routerImg9 = require('@/assets/img/nav/yaohe.png');
    routerImg10 = require('@/assets/img/nav/yajieshi.png');
    routerImg11 = require('@/assets/img/nav/junban.png');

    //宣教
    video1Name = '牙齿松动';
    video2Name = '退缩';
    video3Name = '牙齿肿胀,发炎';
    video4Name = '口臭';
    video5Name = '出血';
    video6Name = '激光牙周治疗';
    video7Name = '翻瓣刮治术';
    video8Name = '红肿';

    public getSex(params: string): string {
        return params
    }
    //说明
    url = require('@/assets/img/supCheck.png');
    srcList = [require('@/assets/img/supCheck.png')];
    imgList = [
        {
            name: '辅助检查',
            url: require('@/assets/img/supCheck.png'),
        },
        {
            name: '牙周炎判断',
            url: require('@/assets/img/judge.png'),
        }
    ]
}
const chineseLanguage = new ChineseLanguage();
export default chineseLanguage