import voice from '@/scripts/Voice'
import GlobalSesion from '@/scripts/GlobalSession'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import templang from "@/scripts/TempLanguage";
import chineseLanguage from '@/scripts/ChineseLanguage';
import englishLanguage from '@/scripts/EnglishLanguage';
class Session {
  public static value: GlobalSesion | null = null
}
const chineseNameArr = [chineseLanguage.teethCheck, chineseLanguage.PeriodontalTitle, chineseLanguage.Bleeding, chineseLanguage.Pyorrhea, chineseLanguage.relax, chineseLanguage.hornificat, chineseLanguage.occlus, chineseLanguage.Calculus, chineseLanguage.fork, chineseLanguage.briefing,chineseLanguage.plaque]
const englishNameArr = [englishLanguage.teethCheck, englishLanguage.PeriodontalTitle, englishLanguage.Bleeding, englishLanguage.Pyorrhea, englishLanguage.relax, englishLanguage.hornificat, englishLanguage.occlus, englishLanguage.Calculus, englishLanguage.fork, englishLanguage.briefing,englishLanguage.plaque]
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    component: () => import('../views/IndexView.vue'),
    children: [
      {
        path: '/home',
        name: 'index',
        redirect: '/simple',
        component: () => import('../views/HomeView.vue'),
        children: [
          {
            path: '/tooth',
            name: 'tooth',
            meta: { routeName: templang.value.teethCheck },
            component: () => import('../views/check/ToothView.vue')
          },
          {
            path: '/measure',
            name: 'measure',
            meta: { routeName: templang.value.PeriodontalTitle },
            component: () => import('../views/check/MeasureView.vue')
          },
          {
            path: '/bleed',
            name: 'bleed',
            meta: { routeName: templang.value.Bleeding },
            component: () => import('../views/check/BleedView.vue')
          },
          {
            path: '/pyorrhea',
            name: 'pyorrhea',
            meta: { routeName: templang.value.Pyorrhea },
            component: () => import('../views/check/PyorrheaView.vue')
          },
          {
            path: '/relax',
            name: 'relax',
            meta: { routeName: templang.value.relax },
            component: () => import('../views/check/RelaxView.vue')
          },
          {
            path: '/hornificat',
            name: 'hornificat',
            meta: { routeName: templang.value.hornificat },
            component: () => import('../views/check/HornificatView.vue')
          },
          {
            path: '/occlus',
            name: 'occlus',
            meta: { routeName: templang.value.occlus },
            component: () => import('../views/check/OcclusView.vue')
          },
          {
            path: '/calculus',
            name: 'calculus',
            meta: { routeName: templang.value.Calculus },
            component: () => import('../views/check/CalculusView.vue')
          },
          {
            path: '/fork',
            name: 'fork',
            meta: { routeName: templang.value.fork },
            component: () => import('../views/check/ForkView.vue')
          },
          {
            path: '/plaque',
            name: 'plaque',
            meta: { routeName: templang.value.plaque },
            component: () => import('../views/check/PlaqueView.vue')
          },
          {
            path: '/simple',
            name: 'simple',
            meta: { routeName: templang.value.briefing },
            component: () => import('../views/check/SimpleView.vue')
          }
        ]
      },
      {
        path: '/audit',
        name: 'audit',
        meta: { admin: true },
        component: () => import('../views/AuditView.vue')
      },
    ]
  },
  {
    path: '/patient',
    name: 'patient',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/patientInfo',
    name: 'patientInfo',
    component: () => import('../views/patientInfo.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/caseHistory',
    name: 'caseHistory',
    component: () => import('../views/CaseHistoryView.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to?.meta?.routeName) {
    if (chineseNameArr.indexOf(to?.meta?.routeName as string) > -1) {
      chineseNameArr.indexOf(to?.meta?.routeName as string)
      if (templang.value.model == 'model') {
        voice.play(englishNameArr[chineseNameArr.indexOf(to?.meta?.routeName as string)] + templang.value.model, true)
      }
      else {
        voice.play(chineseNameArr[chineseNameArr.indexOf(to?.meta?.routeName as string)] + templang.value.model, true)
      }

    }
  }
  if (to?.meta?.admin) {
    if (Session.value == null || !Session.value?.user.admin) {
      next({ path: "/" })
      return
    }
  }
  next()
})

export default router
export { Session } 
